var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Editor',{ref:"editor",attrs:{"url":_vm.url,"fields":_vm.fields,"filters":_vm.filters,"icon":"mdi-calendar-multiple-check","args":_vm.args,"testo-list":'Fatture',"checkbox":false,"globalActions":_vm.globalActions,"no-insert":!_vm.$auth('amministratore'),"no-update":!_vm.$auth('amministratore'),"no-delete":!_vm.$auth('amministratore')},on:{"refresh":_vm.editor_refresh,"globalAction":_vm.globalAction},scopedSlots:_vm._u([{key:"item.data_pianificazione",fn:function(ref){
var item = ref.item;
return [_c('LocalDate',{attrs:{"time":"","date":item.data_pianificazione}})]}},{key:"item.data_audit",fn:function(ref){
var item = ref.item;
return [_c('LocalDate',{attrs:{"date":item.data_audit}})]}},{key:"item.data_validazione",fn:function(ref){
var item = ref.item;
return [_c('LocalDate',{attrs:{"date":item.data_validazione}})]}},{key:"item.interrotta",fn:function(ref){
var item = ref.item;
return [_c('Boolean',{model:{value:(item.interrotta),callback:function ($$v) {_vm.$set(item, "interrotta", $$v)},expression:"item.interrotta"}})]}},{key:"item.cambio_codice",fn:function(ref){
var item = ref.item;
return [_c('Boolean',{model:{value:(item.cambio_codice),callback:function ($$v) {_vm.$set(item, "cambio_codice", $$v)},expression:"item.cambio_codice"}})]}},{key:"item.descrizione",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"descrizione"},[_c('TextTruncate',{attrs:{"width":"15vw","text":item.descrizione_lotto}}),_c('a',{attrs:{"href":'https://www.google.it/maps/place/' + item.s_referente.indirizzo + ',' + item.s_referente.comune,"target":"_blank"}},[_vm._v(" "+_vm._s(item.s_referente.indirizzo)+" "+_vm._s(item.s_referente.comune)+" "+_vm._s(item.s_referente.provincia)+" ")]),_vm._v(" "+_vm._s(item.s_regione.descrizione)+" ")],1)]}},{key:"item.referente",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.contatto(item)}}},[_vm._v(_vm._s(item.referente_cognome)+" "+_vm._s(item.referente_nome))])]}},{key:"item.stato",fn:function(ref){
var item = ref.item;
return [_c('OrdinativoStato',{attrs:{"stato":item.stato}})]}},{key:"item.stato_requisiti",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.stato_requisiti.eseguiti)+" / "+_vm._s(item.stato_requisiti.totali)+" ")]}},{key:"item.nc.gravi_perc",fn:function(ref){
var item = ref.item;
return [(item.nc.gravi)?_c('span',[_vm._v(" "+_vm._s(item.nc.gravi / item.stato_requisiti.totali * 100)+" % ")]):_vm._e()]}},(!_vm.$auth(['pianificatore', 'ispettore']))?{key:"top",fn:function(){return [_c('v-btn',{staticClass:"mx-2",attrs:{"color":"success"},on:{"click":_vm.download_fatture}},[_vm._v(" Download Fatture ")])]},proxy:true}:null,{key:"extra",fn:function(){return [_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',[_c('p',[_c('v-chip',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(_vm._s(_vm.convenzione.codice))]),_vm._v(" "+_vm._s(_vm.convenzione.nome)),_c('br'),_vm._v(" "+_vm._s(_vm.convenzione.tipo)),_c('br'),_vm._v(" "+_vm._s(_vm.convenzione.tipo_attivita)+" ")],1)])])],1)],1)]},proxy:true},{key:"form",fn:function(ref){
var instance = ref.instance;
return [_c('Relation',{attrs:{"url":"/backend/autocomplete/userprofile","label":"Ispettore","filters":{ruoli:'amministratore,ispettore'}},model:{value:(instance.ispettore),callback:function ($$v) {_vm.$set(instance, "ispettore", $$v)},expression:"instance.ispettore"}}),_c('v-text-field',{attrs:{"label":"Note"},model:{value:(instance.note),callback:function ($$v) {_vm.$set(instance, "note", $$v)},expression:"instance.note"}}),_c('v-text-field',{attrs:{"label":"Referente Nnome"},model:{value:(instance.referente_nome),callback:function ($$v) {_vm.$set(instance, "referente_nome", $$v)},expression:"instance.referente_nome"}}),_c('v-text-field',{attrs:{"label":"Referente Cognome"},model:{value:(instance.referente_cognome),callback:function ($$v) {_vm.$set(instance, "referente_cognome", $$v)},expression:"instance.referente_cognome"}}),_c('v-text-field',{attrs:{"label":"Referente Email"},model:{value:(instance.referente_email),callback:function ($$v) {_vm.$set(instance, "referente_email", $$v)},expression:"instance.referente_email"}}),_c('v-text-field',{attrs:{"label":"Referente Telefono"},model:{value:(instance.referente_telefono),callback:function ($$v) {_vm.$set(instance, "referente_telefono", $$v)},expression:"instance.referente_telefono"}}),_c('v-text-field',{attrs:{"label":"Referente Fax"},model:{value:(instance.referente_fax),callback:function ($$v) {_vm.$set(instance, "referente_fax", $$v)},expression:"instance.referente_fax"}}),_c('v-select',{attrs:{"items":_vm.stati},model:{value:(instance.stato),callback:function ($$v) {_vm.$set(instance, "stato", $$v)},expression:"instance.stato"}})]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [(['eseguito', 'compilato', 'validato', 'validato-consip', 'non-validato-consip'].includes(item.stato))?[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.download_fattura(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-arrow-down-circle")]),_vm._v(" Download ")],1)]}}],null,true)})]:_vm._e()]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item)+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }